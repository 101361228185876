
export const treatmentFormTrigger = () => {
    if (document.getElementById("treatmentSignUpForm")) {
        const formErrors = document.querySelectorAll('.errorlist, .recaptcha-errors');
        const hasFormErrors = formErrors.length > 0;
        if (hasFormErrors) {
            const formElement = document.getElementById("treatment-sign-up");
            formElement.scrollIntoView();
        }

    }
}
